export default class SelectUtil {
    static readonly spanSlotArr = [
        { type: `minutes`, name: `分` },
        { type: `hours`, name: `時間` },
        { type: `days`, name: `日` },
    ]

    static readonly selectMeetingTimeArr = [
        "15分",
        "30分",
        "45分",
        "1時間",
        "1時間15分",
        "1時間30分",
        "1時間45分",
        "2時間",
        "2時間30分",
        "3時間",
        "3時間30分",
        "4時間",
        "4時間30分",
        "5時間",
        `5時間半`,
        `6時間`,
        `6時間半`,
        `7時間`,
        `7時間半`,
        `8時間`,
        `カスタム`,
    ]

    static changeTimeToString(timeNum: number) {
        if (timeNum === 15) {
            return "15分"
        } else if (timeNum === 30) {
            return "30分"
        } else if (timeNum === 45) {
            return "45分"
        } else if (timeNum === 60) {
            return "1時間"
        } else if (timeNum === 75) {
            return "1時間15分"
        } else if (timeNum === 90) {
            return "1時間30分"
        } else if (timeNum === 105) {
            return "1時間45分"
        } else if (timeNum === 120) {
            return "2時間"
        } else if (timeNum === 150) {
            return "2時間30分"
        } else if (timeNum === 180) {
            return "3時間"
        } else if (timeNum === 210) {
            return "3時間30分"
        } else if (timeNum === 240) {
            return "4時間"
        } else if (timeNum === 270) {
            return "4時間30分"
        } else if (timeNum === 300) {
            return "5時間"
        } else if (timeNum === 330) {
            return "5時間半"
        } else if (timeNum === 360) {
            return "6時間"
        } else if (timeNum === 390) {
            return "6時間半"
        } else if (timeNum === 420) {
            return "7時間"
        } else if (timeNum === 450) {
            return "7時間半"
        } else if (timeNum === 480) {
            return "8時間"
        }
        return null
    }

    static changeTimeStringToNum(timeStr: string): number {
        if (timeStr === "15分") {
            return 15
        } else if (timeStr === "30分") {
            return 30
        } else if (timeStr === "45分") {
            return 45
        } else if (timeStr === "1時間") {
            return 60
        } else if (timeStr === "1時間15分") {
            return 75
        } else if (timeStr === "1時間30分") {
            return 90
        } else if (timeStr === "1時間45分") {
            return 105
        } else if (timeStr === "2時間") {
            return 120
        } else if (timeStr === "2時間30分") {
            return 150
        } else if (timeStr === "3時間") {
            return 180
        } else if (timeStr === "3時間30分") {
            return 210
        } else if (timeStr === "4時間") {
            return 240
        } else if (timeStr === "4時間30分") {
            return 270
        } else if (timeStr === "5時間") {
            return 300
        } else if (timeStr === "5時間半") {
            return 330
        } else if (timeStr === "6時間") {
            return 360
        } else if (timeStr === "6時間半") {
            return 390
        } else if (timeStr === "7時間") {
            return 420
        } else if (timeStr === "7時間半") {
            return 450
        } else if (timeStr === "8時間") {
            return 480
        }
    }

    static readonly votedRoomFixTypes = [
        { type: `all`, name: `すべての候補提案者を確定しメールを送信` },
        { type: `attendees_only`, name: `出席対象者のみ確定しメールを送信` },
    ]

    static readonly thanksPageDisplayTypes = [
        { type: `complete`, name: `フォーム完了のテキストを表示` },
        { type: `redirect_url`, name: `指定のページにリダイレクト` },
    ]

    static readonly assignMagRuleOptions = [
        { type: `order`, name: `上から優先順で割り当てる` },
        { type: `random`, name: `ランダムで割り当てる` },
        { type: `equal`, name: `均等になるように割り当てる` },
        { type: `priority`, name: `指定割合になるように割り当てる` },
    ]

    static readonly periodDaysForAssignOptions = [
        { type: 7, name: `直近１週間で` },
        { type: 14, name: `直近２週間で` },
        { type: 30, name: `直近１ヶ月で` },
        { type: 60, name: `直近２ヶ月で` },
        { type: 90, name: `直近３ヶ月で` },
    ]

    static readonly selectMagType = [
        { type: `display_none`, name: `非表示` },
        { type: `display_and_selectable`, name: `お相手が選択可能` },
        { type: `display_after_fix`, name: `確定後に表示` },
    ]

    static readonly selectUserGroupRoles = ["メンバー", "管理者", "カスタム"]

    static readonly selectFormTimingTypes = [
        { type: `open_page`, name: `フォーム先` },
        { type: `with_fix`, name: `日程選択後、日程確定ボタンと一緒に表示` },
        { type: `after_fix`, name: `日程選択後、日程確定ボタン押下後にフォームを表示` },
    ]

    static readonly selectMailFlowTimingTypes = [
        { type: `create_room`, name: `ページ作成時`, available_spans: [`after`] },
        { type: `shared_calendar`, name: `日程提案時`, available_spans: [`after`] },
        { type: `fixed_appointment`, name: `日程確定時`, available_spans: [`after`] },
        { type: `after_start`, name: `イベント開始`, available_spans: [`before`, `after`] },
        { type: `after_canceled`, name: `イベントキャンセル`, available_spans: [`after`] },
    ]

    static readonly selectMailFlowSendToTypes = [
        // { type: `all`, name: `すべて`, available_spans: [`after`] },
        { type: `owner`, name: `オーナー`, available_spans: [`after`] },
        { type: `invitees`, name: `調整相手`, available_spans: [`after`] },
        { type: `selected_mag`, name: `担当者`, available_spans: [`before`, `after`] },
    ]

    static readonly displayScheduleTypes = [
        { type: `anytime`, name: `常に表示する` },
        { type: `never`, name: `常に表示しない` },
        { type: `rules`, name: `ルールに従って表示する` },
        { type: `rules_by_ai`, name: `フォームの内容から、AIが調整カレンダーを自動選択し表示する` },
    ]
    //none,weekend,weekend_and_holiday,custom
    static readonly availablePeriodSkipTypes = [
        { type: `none`, name: `すべての日程のうち` },
        { type: `holiday`, name: `祝日を除く` },
        { type: `weekend`, name: `土日を除く` },
        { type: `weekend_and_holiday`, name: `土日・祝日を除く` },
    ]

    static readonly calendarShareTypes = [
        { type: `detail`, name: `すべての予定の詳細` },
        { type: `fill_only`, name: `時間枠のみ、詳細は非表示` },
    ]

    static readonly mailDisplayTypes = [
        { type: `rich`, name: `リッチ`, description: `スタイルを効かせ表示します` },
        {
            type: `simple`,
            name: `シンプル`,
            description: `テキストメールのような表示になります。ロゴは表示されません。`,
        },
    ]

    static readonly commonDisplayTypes = [
        { type: `display`, name: `表示`, description: `チャットの通知に送信内容を含みます。` },
        {
            type: `none`,
            name: `非表示`,
            description: `チャットには通知のみ表示されます。`,
        },
    ]

    static readonly cancelSpanTypes = [
        { type: `display`, name: `常に表示する`, number: null },
        { type: `none`, name: `常に表示しない`, number: -1 },
        { type: `span`, name: `指定期間まで表示する`, number: 60 * 24 },
        { type: `time_at`, name: `指定日付まで表示する`, number: -2 },
    ]

    static readonly requestButtonTypes = [
        { type: `display`, name: `表示`, number: null },
        { type: `none`, name: `非表示`, number: -1 },
    ]

    static readonly availableOrNotTypes = [
        { type: `none`, name: `設定しない`, number: null },
        // 設定した場合、1時間前をデフォルトとする.
        { type: `set`, name: `設定する`, number: 60 },
    ]

    static readonly canChangeOrNot = [
        { type: `can_change`, name: `変更可能`, number: true },
        // 設定した場合、1時間前をデフォルトとする.
        { type: `none`, name: `変更不可`, number: false },
    ]

    static readonly autoShareMyRoomTypes = [
        { type: `none`, name: `共有しない`, number: null, is_default: true },
        { type: `owner_only`, name: `オーナーが複製した場合に自動共有`, number: null, is_default: false },
        // { type: `members_only`, name: `オーナーと共有メンバーが複製した場合に自動共有`, number: null, is_default: false },
    ]

    /**
     * typesの中からdefaultTypeに一致するnameを返します。
     * @param types [{type: string, name: string}]
     * @param defaultType デフォルトのtype
     * @param defaultName defaultTypeが見つからない場合に返す値
     * @returns
     */
    static defaultName(types: any[], defaultType: string) {
        return types.find(t => t.type === defaultType)?.name || types.find(t => t.is_default)?.name
    }

    static readonly additionalMailSubjectTypes = [
        { type: `none`, name: `なし`, number: null, is_default: true },
        { type: `prefix`, name: `メールタイトルの前に追加`, number: null, is_default: false },
        { type: `suffix`, name: `メールタイトルの後ろに追加`, number: null, is_default: false },
    ]

    static readonly availableZoomSettingJoinBeforeHost = [
        { type: `none`, name: `参加者の入室を許可しない`, number: null },
        { type: `permit`, name: `参加者の入室を許可する`, number: null },
    ]

    static readonly zoomPasscodeTypes = [
        { type: `normal`, name: `6桁の数字`, number: null },
        { type: `secure`, name: `セキュアな設定`, number: null },
        { type: `none`, name: `ユーザーのデフォルトのパスコード（PMI）を利用`, number: null },
    ]

    //normal, same_ug_only, same_ug_admin_only
    static readonly fieldDisplayTypes = [
        { type: `normal`, name: `表示`, number: null },
        { type: `same_ug_only`, name: `非表示（同一グループのみ表示・入力可能）`, number: null },
        { type: `same_ug_admin_only`, name: `非表示（同一グループの管理者権限のみ表示・入力可能）`, number: null },
    ]

    static readonly addressbookAutoRegiTypes = [
        { type: `auto_save`, name: `自動登録する`, number: null },
        { type: `do_not_save`, name: `自動登録しない`, number: null },
    ]

    static readonly searchRoomsTypes = [
        { type: `auto_save`, name: `自動登録する`, number: null },
        { type: `do_not_save`, name: `自動登録しない`, number: null },
    ]

    static readonly hoursPer30: number[] = Array(48)
        .fill(0)
        .map((_, i) => i)

    static readonly hoursPer15: number[] = Array(96)
        .fill(0)
        .map((_, i) => i)
    // { type: `custom`, name: `カスタム` },
    // static readonly selectLocationTypes = ["オフライン", "オンライン"]
    static readonly timeArray: string[] = Array(96)
        .fill(0)
        .map((_, i) => {
            let str = ``
            let hour = `${Math.floor(i / 4)}`
            str = `${hour.padStart(2, "0")}:`
            let min = `${(i % 4) * 15}`
            str += `${min.padStart(2, "0")}`
            return str
        })

    static readonly selectShowVoteTypes = [
        { type: `hide_all`, name: `非公開`, number: null },
        { type: `display_pinned`, name: `ピンした投票ページのみを公開する`, number: null },
        { type: `display_all`, name: `全員に公開する`, number: null },
    ]

    static readonly selectShowFilterVoteTypes = [
        { type: `none`, name: `投票日程をそのまま羅列する`, number: null },
        { type: `filter_by_date`, name: `日にちを選択したあと、投票日程を表示`, number: null },
    ]

    static readonly selectMaxBookingsShowTypes = [
        { type: `none`, name: `表示しない`, number: null },
        { type: `show_slot_remain`, name: `残り予約数を表示する（残り◯）`, number: null },
        { type: `show_slot_and_max`, name: `予約数と最大予約数を表示する（◯/全数）`, number: null },
    ]

    static readonly selectSendChannelTypes = [
        { type: `email`, name: `メールのみ`, number: null },
        { type: `sms`, name: `SMSのみ`, number: null },
        { type: `email_and_sms`, name: `メールとSMS`, number: null },
    ]

    static readonly calendarShowTypes = [
        { type: `monthly_monthly`, name: `PC:月次, スマホ:月次` },
        { type: `monthly_weekly`, name: `PC:月次, スマホ:週次` },
        { type: `weekly_weekly`, name: `PC:週次, スマホ:週次` },
        { type: `weekly_monthly`, name: `PC:週次, スマホ:月次` },
        // { type: `smart_ab_test`, name: `自動でABテストを行い、その後コンバージョンが高い方に自動最適化されます.` },
    ]

    static readonly openPeriodTypes = [
        { type: `none`, name: `公開期間を設定しない` },
        { type: `start_time_only`, name: `開始時間のみ設定` },
        { type: `end_time_only`, name: `終了時間のみ設定` },
        { type: `start_and_end_time`, name: `開始時間と終了時間を指定` },
    ]

    static readonly suggestedDatesDisplayType = [
        { type: `all`, name: `すべて表示`, description: `提案されたすべての日程を表示します。` },
        {
            type: `period`,
            name: `期間指定`,
            description: `選択した期間を公開（提案に更新があるとその期間内だった場合、自動で公開されます）`,
        },
        { type: `select_self`, name: `個別日程指定`, description: `手動で選択した日程のみを公開` },
    ]

    static readonly designTypes = [
        { type: `normal`, name: `通常`, description: `通常のヘッダーの下に日程が表示されます。` },
        {
            type: `seminar`,
            name: `セミナー用`,
            description: `説明文が長い場合に有効です。`,
        },
    ]
    //    static readonly timeArray: string[] = [`00:00`, `00:15`, `00:30`, `00:45`]

    static readonly checkFieldTypes = [
        { type: `email`, name: `メールアドレス` },
        { type: `phone`, name: `電話番号` },
        { type: `name`, name: `氏名` },
        { type: `public_id`, name: `調整ページID` },
    ]

    static readonly whyDoyouKnowUsTypes = [
        { type: `webinar`, name: `セミナー/ ウェビナーを見て`, description: `` },
        {
            type: `media`,
            name: `メディア記事を読んで`,
            description: ``,
        },
        {
            type: `friend`,
            name: `知人の紹介/関連会社の紹介`,
            description: ``,
        },
        {
            type: `other_department`,
            name: `他の部門からの紹介`,
            description: ``,
        },
        {
            type: `website`,
            name: `当社WEBサイトを見て`,
            description: ``,
        },
        {
            type: `web_ad`,
            name: `WEB広告（Facebook等）を見て`,
            description: ``,
        },
        {
            type: `comparison_site`,
            name: `比較サイトを見て`,
            description: ``,
        },
        {
            type: `sales`,
            name: `弊社からの営業`,
            description: ``,
        },
        {
            type: `other`,
            name: `その他`,
            description: ``,
        },
    ]
}
